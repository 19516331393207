import * as React from "react";
import dayjs from "dayjs";
import toObject from "dayjs/plugin/toObject";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { SplitTable } from "./SplitTable";
import { addTransaction } from "../api";
import { SnackbarContext } from "./SnackbarProvider";
import { config } from "../config";
dayjs.extend(toObject);

const expenseCategoryOptions = [
  "Groceries",
  "Restaurants",
  "Gas",
  "Home",
  "Z Funds",
  "General Savings",
  "Car",
  "Giving",
  "Other",
  "Hygene/Health",
  "Kids",
  "Vacation",
  "Business",
  "Utilities",
  "Insurance",
  "Investment",
  "Rent",
  "Tithes",
  "Subscriptions",
  "Loans",
];

const incomeCategoryOptions = [
  "Contracting",
  "Credit Cards",
  "FSA Daycare Reimbusement",
  "Gifts",
  "Investment Returns",
  "Other",
  "Palouse Sound Rentals",
  "Pullman Guitar Setups",
  "Savings",
  "Selling",
  "Transfer",
  "Zochil's Job",
];

export const sortedExpenseCategories = expenseCategoryOptions.sort();
export const sortedIncomeCategories = incomeCategoryOptions.sort();

export function TransactionPage() {
  const [transactionType, setTransactionType] = React.useState("expense");
  const [total, setTotal] = React.useState("");
  const [cat, setCat] = React.useState("");
  const [date, setDate] = React.useState(dayjs());
  const [desc, setDesc] = React.useState("");
  const [error, setError] = React.useState(false);
  const [isSplitChecked, setIsSplitChecked] = React.useState(false);
  const [isCash, setIsCash] = React.useState(false);
  const [isSendingTransaction, setIsSendingTransaction] = React.useState(false);
  const [splits, setSplits] = React.useState([]);

  const { raiseSuccessToast, raiseErrorToast } =
    React.useContext(SnackbarContext);

  const handleToggleChange = (event, newToggleValue) => {
    if (newToggleValue === null) return;
    setCat("");
    setTransactionType(newToggleValue);
    setIsCash(false);
  };

  const sortedCategoryOptions =
    transactionType === "expense"
      ? sortedExpenseCategories
      : sortedIncomeCategories;

  function resetAllInputs() {
    setTotal("");
    setCat("");
    setDesc("");
    setError(false);
    setIsSplitChecked(false);
    setSplits([]);
    setIsCash(false);
  }

  const isSplit = transactionType === "expense" && isSplitChecked;

  function handleTotal(e) {
    setTotal(e);
  }

  const logTransaction = async (e) => {
    e.preventDefault();
    const regex = /^-?\d+(\.\d{1,2})?$/; // pattern for valid monetary value

    if (regex.test(total)) {
      setError(false);
      let transaction = {};
      if (isSplit) {
        transaction = {
          transactionType,
          date: date.format("MM-DD-YYYY"),
          totalAmount: Number(total),
          isSplit: isSplit,
          isCash: isCash,
          store: desc,
          splits: splits.map((split) => ({
            ...split,
            amount: Number(split.amount),
          })),
        };
      } else {
        transaction = {
          transactionType,
          date: date.format("MM-DD-YYYY"),
          totalAmount: Number(total),
          isSplit: isSplit,
          isCash: isCash,
          description: desc,
          category: cat,
        };
      }
      setIsSendingTransaction(true);
      const response = await addTransaction(transaction);
      setIsSendingTransaction(false);
      if (response.success) {
        raiseSuccessToast(response.message);
        resetAllInputs();
      } else {
        raiseErrorToast("Error: " + response.message);
      }
    } else {
      setError(true);
    }
  };

  const handleCatChange = (event) => {
    setCat(event.target.value);
  };

  const toggleIsSplitChecked = () => {
    setIsSplitChecked(!isSplitChecked);
  };

  const toggleIsCash = () => {
    setIsCash(!isCash);
  };

  const shouldLogTransactionBeDisabled =
    isSplit &&
    Math.round(
      splits.reduce((acc, split) => acc + Number(split.amount), 0) * 100
    ) /
      100 !==
      Number(total);

  return (
    <div className="App">
      <h3>{config.sheetName}</h3>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box className="appBox">
          <form onSubmit={logTransaction}>
            <FormControl className="form">
              <DatePicker
                label="Basic date picker"
                value={date}
                onChange={setDate}
              />
              <ToggleButtonGroup
                value={transactionType}
                exclusive
                onChange={handleToggleChange}
                color="primary"
                fullWidth
              >
                <ToggleButton value="expense">Expense</ToggleButton>
                <ToggleButton value="income">Income</ToggleButton>
              </ToggleButtonGroup>
              <div className="totalAmountSplit">
                <TextField
                  label="Total Amount"
                  error={error}
                  variant="outlined"
                  id="total"
                  value={total}
                  onChange={(e) => handleTotal(e.target.value)}
                  helperText={error ? "Error: Please enter a vaild amount" : ""}
                  type="number"
                />
                <div className="checkboxes">
                  {transactionType === "expense" ? (
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Split Up"
                      onChange={toggleIsSplitChecked}
                      checked={isSplitChecked}
                    />
                  ) : null}
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Cash"
                    onChange={toggleIsCash}
                    checked={isCash}
                  />
                </div>
              </div>

              {isSplit ? (
                <TextField
                  label="Store"
                  variant="outlined"
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                />
              ) : (
                <>
                  <TextField
                    label="Description"
                    variant="outlined"
                    onChange={(e) => setDesc(e.target.value)}
                    value={desc}
                  />

                  <FormControl>
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                      labelId="category-label"
                      label="Category"
                      id="category"
                      onChange={handleCatChange}
                      value={cat}
                    >
                      {sortedCategoryOptions.map((option) => (
                        <MenuItem key={transactionType + option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              <SplitTable
                isActive={isSplit}
                total={total}
                setTotal={setTotal}
                splits={splits}
                setSplits={setSplits}
              ></SplitTable>

              <div className="center">
                {isSendingTransaction ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                    className="submitButton"
                    disabled={shouldLogTransactionBeDisabled}
                  >
                    Log Transaction
                  </Button>
                )}
              </div>
            </FormControl>
          </form>
        </Box>
      </LocalizationProvider>
    </div>
  );
}
