import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  InputLabel,
  formHelperTextClasses,
  Paper,
} from "@mui/material";
import * as React from "react";

import { RemainingAmount } from "./RemainingAmount";
import { sortedExpenseCategories } from "./TransactionPage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  borderRadius: "3rem",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1rem",
};

export function SplitTable({ isActive, total, splits, setSplits }) {
  const [modalState, setModalState] = React.useState(false);
  const handleClose = () => {
    setModalState(false);
    setSplitAmount("");
    setSplitDesc("");
    setSplitCategory("");
    setTaxCategory("");
    setTaxRate(0);
    setAmountDisable(false);
  };

  const [amountDisable, setAmountDisable] = React.useState(false);

  const [splitTotal, setSplitTotal] = React.useState(total);

  const [splitAmount, setSplitAmount] = React.useState("");
  const [splitAmountPlusTax, setSplitAmountPlusTax] = React.useState(0);
  const [splitItems, setSplitDesc] = React.useState("");
  const [splitCategory, setSplitCategory] = React.useState("");
  const [taxCatergory, setTaxCategory] = React.useState("");

  const [taxRate, setTaxRate] = React.useState(0);

  function createSplit(amount, items, category) {
    let id = crypto.randomUUID();
    return { id, amount, items, category };
  }

  function handleRemoveSplit(id) {
    const updatedSplits = splits.filter((split) => split.id !== id);
    setSplits(updatedSplits);
  }

  function handleRemaining() {
    const roundedSplitAmount = parseFloat(total - splitTotal).toFixed(2);
    setSplitAmount(roundedSplitAmount);
    setTaxCategory("Other");
    setTaxRate(1.0);
    amountDisable ? setAmountDisable(false) : setAmountDisable(true);
  }

  React.useEffect(() => {
    let sum = 0;
    splits.forEach((split) => {
      sum += parseFloat(split.amount);
    });
    setSplitTotal(sum);
  }, [splits]);

  const disableAddSplit = Math.round(splitTotal * 100) / 100 == total;

  React.useEffect(() => {
    switch (taxCatergory) {
      case "Washington":
        setTaxRate(1.08);

        break;
      case "Idaho":
        setTaxRate(1.06);

        break;

      case "Untaxed":
        setTaxRate(1.0);
        break;

      case "Other":
        break;

      default:
        setTaxRate(1.0);
        break;
    }
  }, [taxCatergory]);

  React.useEffect(() => {
    try {
      const evalSplitAmount = eval(splitAmount);
      const roundedPlusTax = parseFloat(evalSplitAmount * taxRate).toFixed(2);
      setSplitAmountPlusTax(roundedPlusTax);
    } catch (error) {
    }
  }, [taxRate, splitAmount]);

  function handleSplitAmountChange(value) {
    const regex = /^[-+*/.0-9]*$/;

    if (regex.test(value)) {
      setSplitAmount(value);
    }
  }

  function handleAddSplit() {
    const remainingAmount = (total - splitTotal).toFixed(2); // Fix rounding issue
    
    const splitAmountWithTax = parseFloat(splitAmount) * taxRate;
    const splitAmountPlusTaxFormatted = splitAmountWithTax.toFixed(2); // Fix rounding issue
    
    if(isNaN(splitAmountPlusTax)){
      alert("Invalid input.")
      return;
    }

    if (parseFloat(splitAmountPlusTaxFormatted) > parseFloat(remainingAmount)) {
      alert("Insufficient remaining amount for this split.");
      return;
    }

    const newSplit = createSplit(splitAmountPlusTax, splitItems, splitCategory);
    setSplits((prevSplits) => [...prevSplits, newSplit]);
    setSplitAmount("");
    setSplitDesc("");
    setSplitCategory("");
    setAmountDisable(false);
    setModalState(false);
  }

  function openSplitModal() {
    setModalState(true);
  }

  if (isActive === true) {
    return (
      <>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Remove?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {splits?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.amount}
                  </TableCell>
                  <TableCell align="right">{row.items}</TableCell>
                  <TableCell align="right">{row.category}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleRemoveSplit(row.id)}
                    >
                      X
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal open={modalState} onClose={handleClose}>
          <Box sx={style}>
            <FormControl>
              <TextField
                label="Amount"
                value={splitAmount}
                disabled={amountDisable}
                onChange={(e) => handleSplitAmountChange(e.target.value)}
                // type="number"
              />
            </FormControl>
            <br />
            <div className="two-column">
              <p>X {taxRate}</p>
              <FormControl>
                <Select
                  value={taxCatergory}
                  sx={{ width: 150 }}
                  disabled={amountDisable}
                  onChange={(e) => setTaxCategory(e.target.value)}
                >
                  <MenuItem value="Washington">Washington</MenuItem>
                  <MenuItem value="Idaho">Idaho</MenuItem>
                  <MenuItem value="Untaxed">Untaxed</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>

            {taxCatergory === "Other" ? (
              <FormControl>
                <TextField
                  label="Tax Rate"
                  value={taxRate}
                  onChange={(e) => setTaxRate(e.target.value)}
                />
              </FormControl>
            ) : (
              <></>
            )}
            <div className="two-column">
              <p>={splitAmountPlusTax}</p>
              <FormControlLabel
                control={<Checkbox onChange={handleRemaining} />}
                label="Use Remaining"
              />
            </div>
            <FormControl>
              <TextField
                label="Items"
                value={splitItems}
                onChange={(e) => setSplitDesc(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ mb: 2 }}>
              <InputLabel>Category</InputLabel>
              <Select
                sx={{ width: 250 }}
                value={splitCategory}
                onChange={(e) => setSplitCategory(e.target.value)}
              >
                {sortedExpenseCategories.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button variant="contained" onClick={handleAddSplit}>
              Add Split
            </Button>
          </Box>
        </Modal>
        <Button variant="text" onClick={openSplitModal} disabled={disableAddSplit}>
          Add Split
        </Button>
        {total - splitTotal === 0 ? (
          <p>All the money has been allocated</p>
        ) : (
          <RemainingAmount remaining={total - splitTotal} />
        )}
      </>
    );
  }
}
