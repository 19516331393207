// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, useFunctionsEmulator, connectFunctionsEmulator  } from "firebase/functions";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyApZOQLliLwceSP0oEpw5k9Czcfddg81qg",
  authDomain: "burke-budget-app.firebaseapp.com",
  projectId: "burke-budget-app",
  storageBucket: "burke-budget-app.appspot.com",
  messagingSenderId: "593284523400",
  appId: "1:593284523400:web:07be3f9a412292751ac63d"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Functions
export const functions = getFunctions(app);

// comment to true when doing dev work
if (false) { 
  connectFunctionsEmulator(functions, "localhost", 5001);
}