import { httpsCallable } from "firebase/functions";
import { functions } from "./initFirebase"; // replace with your actual path
import { config } from "./config";

export async function addTransaction(transaction) {
  const addTransactionFunction = httpsCallable(functions, 'addTransaction');
  try {
    const result = await addTransactionFunction({sheetId: config.sheetId, transaction});
    return result.data;
  } catch (error) {
    console.error('Error calling addTransaction function:', error);
    throw error;
  }
}