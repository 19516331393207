import React, { createContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export const SnackbarContext = createContext();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleClose = () => {
    setOpen(false);
  };

  const raiseSuccessToast = (message) => {
    setMessage(message);
    setSeverity('success');
    setOpen(true);
  };

  const raiseErrorToast = (message) => {
    setMessage(message);
    setSeverity('error');
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ raiseSuccessToast, raiseErrorToast }}>
      {children}
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
