import React, { useRef, useState } from "react";
import { TransactionPage } from "./components/TransactionPage";
import "./App.css";
import { SnackbarProvider } from "./components/SnackbarProvider";

const thePassword = "DasFWWQrqOlaAoysemgT";
const storedPassword = localStorage.getItem("password");

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(storedPassword === thePassword)
  const passwordInputRef = useRef();

  const handleLogin = (password) => {
    if (password === thePassword) {
      localStorage.setItem("password", password);
      setIsLoggedIn(true);
    } else {
      alert("Incorrect Password");
    }
  };

  if (!isLoggedIn) return (
    <div>
      <h1>Enter Password</h1>
      <input ref={passwordInputRef} type="password" />
      <button onClick={() => {
        handleLogin(passwordInputRef.current.value)
      }}>Login</button>
    </div>
  )

  return (
    <SnackbarProvider>
      <TransactionPage />
    </SnackbarProvider>
  );
}

export default App;
